import styled from 'styled-components'

export const DrawerContent = styled.button`
  height: 40px;
  width: 100%;
  background-color: #ffffff;
  margin-top: 15px;
  border-radius: 8px;
  border-width: 0;
  font-size: 1.25rem;
  color: #525252;
  font-family: 'Lucida Sans', sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background-color: #c9c9c9;
    transition: background-color 0.1s ease-in-out;
  }
  a {
    color: #b453ff;
    text-decoration: none;
  }
  a:hover {
    color: #8838c4;
    transition: all 400ms;
  }
`
