import React from 'react'
import { Description } from '../../shared/Description'
import { Container } from './styles/Container'

export const Footer: React.FC = () => {
  return (
    <Container>
      <Description>Xarlat© todos os direitos reservados.</Description>
    </Container>
  )
}
