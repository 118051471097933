import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles/Container';
import { Drawer } from './styles/Drawer';
import { ReactComponent as Menu } from '../../assets/menu.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { DrawerContent } from './styles/DrawerContent';
import { ButtonIcon } from '../../shared/ButtonIcon';
import { NavBar } from './styles/NavBar';
import { ListItem } from './styles/ListItem';
import { HeaderLimit } from './styles/HeaderLimit';

export const Header: React.FC = () => {
  const [drawer, setDrawer] = useState(false);

  return (
    <Container>
      <HeaderLimit>
        {drawer && (
          <Drawer>
            <ButtonIcon>
              <Close onClick={() => setDrawer(!drawer)} />
            </ButtonIcon>
            <DrawerContent>
              <Link to="/">Home</Link>
            </DrawerContent>
            <DrawerContent>
              <Link to="/politics">Política</Link>
            </DrawerContent>
            <DrawerContent>
              <Link to="/quem-somos">Quem somos</Link>
            </DrawerContent>
          </Drawer>
        )}
        <Logo />
        <NavBar>
          <ListItem>
            <Link to="/">Home</Link>
          </ListItem>
          <ListItem>
            <Link to="/politics">Política</Link>
          </ListItem>
          <ListItem>
            <Link to="/quem-somos">Quem somos</Link>
          </ListItem>
        </NavBar>
        <ButtonIcon onClick={() => setDrawer(!drawer)}>
          <Menu />
        </ButtonIcon>
      </HeaderLimit>
    </Container>
  )
}
