import styled from 'styled-components'

export const Container = styled.div`
  height: 70px;
  width: calc(100% - 30px);
  box-shadow: 0 2px 10px #cccccc;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  margin-top: 250px;

  @media (min-width: 800px) {
    justify-content: center;
    margin-top: 0;
    button {
      display: none;
    }
  }
`
