import styled from 'styled-components'

export const ButtonIcon = styled.button`
  height: 50px;
  width: 50px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 40px;
  border-width: 0;
  cursor: pointer;
  &:active {
    background-color: #0000001e;
  }
`
