// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QuemSomos from './pages/Home/components/QuemSomos/QuemSomos';
import Home from './pages/Home/components/HomePage/HomePage';
import Politics from './pages/Home/components/Politics/politica';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos" element={<QuemSomos />} />
        <Route path="/politics" element={<Politics />} />
      </Routes>
    </Router>
  )
}

export default App
