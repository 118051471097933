/* eslint-disable max-len */
import React from 'react';
import { Header } from '../../../../layouts/Header';
import './styles/politics.scss';
import { Footer } from '../../../../layouts/Footer';
import FotoBanner from './assets/FotoBanner.png';

const Politics = () => {
  return (
    <div>
      <Header />
      <div className="first-div">
        <div className="text">
          <h1 style={{ fontFamily: 'Telegraf Bold', textAlign: 'initial' }}>Política de <br /> Privacidade e<br /> Termos de Uso</h1>
        </div>
        <div className="image">
          <img src={FotoBanner} alt="Imagem de Fundo" />
        </div>
      </div>
      <div style={{ fontFamily: 'Telegraf Regular', textAlign: 'center', margin: '50px' }}>
        <h2>Política de Privacidade</h2>
      </div>
      <div style={{ fontFamily: 'Telegraf Regular', textAlign: 'initial', margin: '50px' }}>

        <p>
          O Xarlat desenvolveu o aplicativo como um aplicativo Freemium. Este SERVIÇO é fornecido pelo Xarlat
          gratuitamente e destina-se a ser usado no estado em que se encontra.
        </p>
        <p>
          Esta página é utilizada para informar os visitantes sobre as políticas de coleta, uso e divulgação de
          Informações Pessoais caso alguém decida utilizar nosso Serviço.
        </p>
        <p>Se você optar por utilizar nosso Serviço, concorda com a coleta e uso de informações em relação a esta
          política. As Informações Pessoais que coletamos são usadas para fornecer e melhorar o Serviço.</p>
        <p>Não usaremos nem compartilharemos suas informações com ninguém, exceto conforme descrito nesta
          Política de Privacidade.</p>
        <p>Os termos utilizados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e
          Condições, acessíveis em Xarlat, a menos que definido de outra forma nesta Política de Privacidade.</p>
        <h3>Coleta e Uso de Informações</h3>
        <p>
          Para uma melhor experiência, enquanto usa o nosso Serviço, podemos exigir que você nos forneça certas
          informações pessoalmente identificáveis, incluindo, mas não se limitando a Nome, Número de Telefone, Data
          de Nascimento, Gênero, Sexualidade, Foto, Câmera, Localização, Mensagens, Conversas, Histórico de
          Mensagens, Tecnologias de Rastreamento para coletar informações sobre como os usuários interagem com
          o aplicativo. As informações que solicitamos serão retidas em seu dispositivo e não são coletadas por nós de
          forma alguma.
        </p>
        <p>O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.</p>
        <p>Link para a política de privacidade dos provedores de serviços de terceiros usados pelo aplicativo:</p>
        <ul>
          <li>Google Play Services</li>
          <li>Google Analytics for Firebase</li>
          <li>Firebase Crashlytics</li>
          <li>Expo</li>
        </ul>
        <h3>Registros</h3>
        <p>Quero informar que sempre que você usa nosso Serviço, em caso de erro no aplicativo, eu coleto dados e
          informações (por meio de produtos de terceiros) em seu telefone chamados Registros. Esses Registros
          podem incluir informações como o endereço de Protocolo de Internet (&quot;IP&quot;) do seu dispositivo, nome do
          dispositivo, versão do sistema operacional, a configuração do aplicativo ao utilizar meu Serviço, data e hora do
          uso do Serviço e outras estatísticas.</p>
        <h3>Cookies</h3>
        <p>Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como
          identificadores exclusivos anônimos. Eles são enviados para o navegador a partir dos sites que você visita e
          são armazenados na memória interna do seu dispositivo.
          Este Serviço não usa esses &quot;cookies&quot; explicitamente. No entanto, o aplicativo pode usar códigos e bibliotecas
          de terceiros que usam &quot;cookies&quot; para coletar informações e melhorar seus serviços. Você tem a opção de
          aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado para o seu dispositivo. Se
          você optar por recusar nossos cookies, pode não ser capaz de usar algumas partes deste Serviço.</p>
        <h3>Provedores de Serviços</h3>
        <p>Podemos empregar empresas e indivíduos terceirizados pelos seguintes motivos:</p>
        <ul>
          <li>Facilitar o nosso Serviço;oto</li>
          <li>Fornecer o Serviço em nosso nome;</li>
          <li>Executar serviços relacionados ao Serviço; ou</li>
          <li>Nos ajudar a analisar como nosso Serviço é usado.</li>
        </ul>
        <p>
          Informamos aos usuários deste Serviço que essas terceiras partes têm acesso às suas Informações
          Pessoais. A razão é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não
          divulgar ou usar as informações para qualquer outro fim.
        </p>
        <h3>Segurança</h3>
        <p>
          Valorizamos a sua confiança ao nos fornecer suas Informações Pessoais, por isso estamos nos esforçando
          para usar meios comercialmente aceitáveis para protegê-las. No entanto, lembre-se de que nenhum método
          de transmissão pela internet ou meio de armazenamento eletrônico é 100% seguro e confiável, e não
          podemos garantir sua segurança absoluta.
        </p>
        <h3>Links para Outros Sites</h3>
        <p>Este Serviço pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado para
          esse site. Observe que esses sites externos não são operados por nós. Portanto, recomendo vivamente que
          reveja a Política de Privacidade desses sites. Não temos controle sobre e não assumimos qualquer
          responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de
          terceiros.</p>
        <h3>Privacidade de Menores</h3>
        <p>Estes Serviços não se destinam a menores de 18 anos. Não coletamos intencionalmente informações
          pessoalmente identificáveis de menores de 18 anos. No caso de descobrirmos que um menor de 18 anos nos
          forneceu informações pessoais, excluiremos essas informações de nossos servidores. Se você for pai ou
          responsável e estiver ciente de que seu filho nos forneceu informações pessoais, entre em contato para que
          possamos tomar as medidas necessárias.</p>
        <h3>Alterações a esta Política de Privacidade</h3>
        <p>Podemos atualizar nossa Política de Privacidade periodicamente. Portanto, é aconselhável que você reveja
          esta página periodicamente para quaisquer alterações. Notificaremos você sobre quaisquer alterações
          postando a nova Política de Privacidade nesta página.
          Esta política é eficaz a partir de 21 de setembro de 2023.</p>
        <h3>Entre em Contato</h3>
        <p>Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em entrar em
          contato conosco pelo e-mail contato@xarlat.com.</p>
        <h3 style={{ fontFamily: 'Telegraf Regular', textAlign: 'center', margin: '50px' }}>Termos e condições</h3>
        <p>Ao fazer o download ou usar o aplicativo, esses termos se aplicarão automaticamente a você - portanto,
          certifique-se de lê-los com atenção antes de usar o aplicativo.</p>
        <p>Você não está autorizado a copiar ou modificar o aplicativo, qualquer parte do aplicativo ou nossas marcas de
          forma alguma.</p>
        <p>Você não está autorizado a tentar extrair o código-fonte do aplicativo, e também não deve tentar traduzir o
          aplicativo para outros idiomas ou criar versões derivadas. O próprio aplicativo, bem como todas as marcas
          comerciais, direitos autorais, direitos de banco de dados e outros direitos de propriedade intelectual
          relacionados a ele, ainda pertencem ao Xarlat.</p>
        <p>O Xarlat está comprometido em garantir que o aplicativo seja o mais útil e eficiente possível. Por esse motivo,
          reservamos o direito de fazer alterações no aplicativo ou cobrar por seus serviços a qualquer momento e por
          qualquer motivo. Nunca cobraremos por você pelo aplicativo ou seus serviços sem deixar muito claro
          exatamente pelo que você está pagando.</p>
        <p>O aplicativo Xarlat armazena e processa dados pessoais que você nos forneceu para fornecer nosso Serviço.
          É sua responsabilidade manter seu telefone e o acesso ao aplicativo seguro. Portanto, recomendamos que
          você não faça jailbreak ou root em seu telefone, que é o processo de remover restrições e limitações de
          software impostas pelo sistema operacional oficial do seu dispositivo. Isso pode tornar seu telefone
          vulnerável a malware/vírus/programas maliciosos, comprometer os recursos de segurança do seu telefone e
          fazer com que o aplicativo Xarlat não funcione corretamente ou de forma alguma.</p>
        <p>O aplicativo utiliza serviços de terceiros que declaram seus Termos e Condições</p>
        <p>Link para os Termos e Condições dos provedores de serviços de terceiros usados pelo aplicativo:</p>
        <ul>
          <li>Google Play Services</li>
          <li>Google Analytics for Firebase</li>
          <li>Firebase Crashlytics</li>
          <li>Expo</li>
        </ul>
        <p>Você deve estar ciente de que há certas coisas pelas quais o Xarlat não se responsabilizará. Certas funções
          do aplicativo exigirão que o aplicativo tenha uma conexão ativa com a Internet. A conexão pode ser Wi-Fi ou
          fornecida pelo seu provedor de rede móvel, mas o Xarlat não pode se responsabilizar pelo fato de o aplicativo
          não funcionar com funcionalidade total se você não tiver acesso ao Wi-Fi e não tiver mais nenhuma cota de
          dados. Se você estiver usando o aplicativo fora de uma área com Wi-Fi, deve lembrar que os termos do
          contrato com seu provedor de rede móvel ainda se aplicarão. Como resultado, você pode ser cobrado pelo
          seu provedor de celular pelo custo dos dados durante a conexão ao acessar o aplicativo ou outras taxas de
          terceiros. Ao usar o aplicativo, você aceita a responsabilidade por essas cobranças, incluindo as taxas de
          roaming de dados se usar o aplicativo fora de seu território de origem (ou seja, região ou país) sem desativar a
          itinerância de dados. Se você não for o pagador da fatura do dispositivo em que está usando o aplicativo,
          esteja ciente de que assumimos que você recebeu permissão do pagador da fatura para usar o aplicativo. Da
          mesma forma, o Xarlat não pode sempre se responsabilizar pela forma como você usa o aplicativo, ou seja,
          você deve se certificar de que seu dispositivo permaneça carregado - se ficar sem bateria e você não
          conseguir ligá-lo para usar o Serviço, o Xarlat não poderá aceitar responsabilidade.</p>
        <p>Com relação à responsabilidade do Xarlat pelo seu uso do aplicativo, ao usar o aplicativo, é importante
          lembrar que, embora nos esforcemos para garantir que ele esteja sempre atualizado e correto, dependemos
          de terceiros para nos fornecer informações para que possamos disponibilizá-las a você. O Xarlat não aceita
          nenhuma responsabilidade por qualquer perda direta ou indireta que você possa sofrer como resultado de
          depender inteiramente dessa funcionalidade do aplicativo.</p>
        <p>Em algum momento, podemos desejar atualizar o aplicativo. O aplicativo está atualmente disponível no
          Android e iOS - os requisitos para ambos os sistemas (e para quaisquer sistemas adicionais aos quais
          decidirmos estender a disponibilidade do aplicativo) podem mudar, e você precisará baixar as atualizações se
          quiser continuar usando o aplicativo. O Xarlat não promete que sempre atualizará o aplicativo para que seja
          relevante para você e/ou funcione com a versão do Android e iOS que você tem instalada em seu dispositivo.
          No entanto, você se compromete a sempre aceitar as atualizações do aplicativo quando oferecidas a você.
          Também podemos desejar interromper o fornecimento do aplicativo e podemos encerrar o uso dele a
          qualquer momento, sem aviso prévio. A menos que informemos o contrário, após qualquer encerramento, (a)
          os direitos e licenças concedidos a você nestes termos serão encerrados; (b) você deve parar de usar o
          aplicativo e, se necessário, excluí-lo do seu dispositivo.</p>
        <p>Alterações a estes Termos e Condições</p>
        <p>Podemos atualizar nossos Termos e Condições de tempos em tempos. Portanto, é aconselhável revisar esta
          página periodicamente para verificar se há alterações. Notificaremos você de quaisquer alterações postando
          os novos Termos e Condições nesta página.</p>
        <p>Estes termos e condições são eficazes a partir de 21 de setembro de 2023.</p>
        <p>Entre em Contato Conosco Se você tiver alguma dúvida ou sugestão sobre meus Termos e Condições, não
          hesite em entrar em contato comigo em contato@xarlat.com.</p>
      </div>
      <Footer />
    </div>
  );
};

export default Politics;
