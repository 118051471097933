/* eslint-disable max-len */
import React from 'react';
import './HowToUse.scss';

const HowToUse = () => {
  return (
    <div className="container">
      <div className="video">
        <iframe
          className="youtube-video"
          width="560"
          height="720"
          src="https://www.youtube.com/embed/IT9qOWxwZ_4?si=dpRb96YLwGVCF169"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="card">
        <h2 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'bold' }}>
          Como Usar o Xarlat:
        </h2>
        <h3 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'bold', color: 'white' }}>
          Um Tutorial Rápido
        </h3>
        <p style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'regular' }}>
          Descubra o poder da conexão autêntica com o Xarlat! Em nosso vídeo tutorial,
          mostramos como você pode usar o aplicativo para conhecer pessoas e fazer
          conexões significativas. Explore as funcionalidades e dicas para uma
          experiência perfeita de flerte, amizade ou networking. Assista ao vídeo e comece a
          conectar-se de maneira fácil e divertida com o Xarlat!
        </p>
      </div>
    </div>
  );
}

export default HowToUse;
