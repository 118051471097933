import styled from 'styled-components'

export const NavBar = styled.ul`
  display: none;
  cursor: pointer;
  @media (min-width: 800px) {
    display: flex;
    width: 470px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin-left: 30px;
    padding: 0;
  }
`
