/* eslint-disable max-len */
import React from 'react';
import { Header } from '../../../../layouts/Header';
import './styles/QuemSomos.css';
import fundoBanner from './assets/fundo_banner.png';
import imagemBanner from './assets/imagem_banner.png';
import imagemNossaMissao from './assets/imagem_nossa_missao.png'
import imagemSobreNos from './assets/imagem_sobre_nos.png'
import { Footer } from '../../../../layouts/Footer'

const QuemSomos = () => {
  const tituloStyle = {
    fontFamily: 'Telegraf, sans-serif',
    fontWeight: 'bold'
  };

  const regularFontStyle = {
    fontFamily: 'Telegraf, sans-serif',
    fontWeight: 'regular'
  };

  const boldFontStyle = {
    fontFamily: 'Telegraf, sans-serif',
    fontWeight: 'bold'
  };

  return (
    <div>
      <Header />
      <div className="quem-somos primeira-secao">
        <div className="background-image">
          <img src={fundoBanner} alt="Background Image" />
          <div className="overlay">
            <div className="overlay-text">
              <p style={tituloStyle}>Transformando conexões, eliminando barreiras</p>
            </div>
            <div className='imagem_banner02'>
              <img className="overlay-image imagem-banner"
                src={imagemBanner}
                alt="Imagem Banner" />
            </div>
          </div>
        </div >
      </div >
      <div className="nova-secao nova-secao-imagem-esquerda">
        <img src={imagemNossaMissao} alt="Sua Imagem" />
        <div className="texto">
          <h2 style={boldFontStyle}>Nossa Missão</h2>
          <p style={regularFontStyle}>Nossa missão é simples: eliminar a incerteza e o
            constrangimento que muitas vezes acompanham o flerte e
            a busca por conexões reais. Acreditamos que todos têm o
            direito de se conectar com outras pessoas de forma
            autêntica, independentemente de suas inseguranças ou
            medos. Estamos aqui para tornar isso possível.</p>
        </div >
      </div >
      <div className="nova-secao nova-secao-imagem-direita">
        <div className="texto">
          <h2 style={boldFontStyle}>Sobre Nós</h2>
          <p style={regularFontStyle}>Muito mais do que um aplicativo de
            relacionamento. Somos dedicados à redefinir a
            maneira como as pessoas se conectam, flertam e
            estabelecem relacionamentos autênticos. Nosso
            compromisso é proporcionar experiências
            significativas e seguras, eliminando os obstáculos
            comuns que surgem nas interações sociais e
            presenciais.</p>
        </div >
        <img src={imagemSobreNos} alt="Nova Imagem" />
      </div >
      <div>
        <h1 className="titulo" style={tituloStyle}>O Que Nos Torna Únicos</h1>
      </div>
      <div className="container">
        <div className="cards">
          <div className="card">
            <h2 className="card-titulo" style={boldFontStyle}>CONEXÕES SEGURAS</h2>
            <p className="card-conteudo" style={regularFontStyle}>O Xarlat utiliza tecnologia
              de Geolocalização e
              solicita fotos em tempo
              real para garantir a
              autenticidade das
              pessoas e promover
              conexões mais seguras.</p>
          </div >
          <div className="card">
            <h2 className="card-titulo" style={boldFontStyle}>FLERTE FACILITADO</h2>
            <p className="card-conteudo" style={regularFontStyle}>Com o Xarlat, o flerte fica
              mais fácil e acessível,
              conectando você a
              pessoas que estão no
              mesmo ambiente que
              você. Facilitando o
              primeiro contato sem
              precisar de muita ação.</p>
          </div>
          <div className="card">
            <h2 className="card-titulo" style={boldFontStyle}>NETWORKING</h2>
            <p className="card-conteudo" style={regularFontStyle}>O Xarlat também pode
              ser usado como forma de
              realizar networking em
              eventos e para criar
              novas amizades.</p>
          </div>
        </div >
      </div >
      <Footer />
    </div >
  )
}

export default QuemSomos
