/* eslint-disable max-len */
import React from 'react';
import './ContactUs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import ManchaFooter from '../../../../../assets/ManchaFooter.png';

const ContactUs = () => {
  return (
    <div className="contact-container">
      <div className="left-content">
        <h2 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black', color: '#9414ED' }}>
          Fale Conosco
        </h2>
        <div className="contact-info">
          <div className="email-container">
            <p className="email-label" style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black', color: '#C572FF' }}>
              E-mail:
            </p>
            <p className="email-info" style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'regular' }}>
              contato@xarlat.com
            </p>
          </div>
        </div>
        <div className="social-icons">
          <p style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black', color: '#C572FF' }}>
            Social
          </p>
          <a href="https://www.instagram.com/xarlatoficial" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram as any} className="icone-preto" />
          </a>
          <a href="https://www.facebook.com/xarlatoficial" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook as any} className="icone-preto" />
          </a>
          <a href="https://twitter.com/xarlatoficial" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter as any} className="icone-preto" />
          </a>
        </div>
      </div>
      <div className="right-content">
        <img src={ManchaFooter} alt="Imagem" />
      </div>
    </div>
  );
}

export default ContactUs;
