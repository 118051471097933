import React from 'react';
import { Footer } from '../../../../layouts/Footer';
import { Header } from '../../../../layouts/Header';
import ContentInitial from './ContentInitial/ContentInitial';
import HowToUse from './HowToUse/HowToUse';
import Depositions from './Depositions/Depositions';
import ContactUs from './ContactUs/ContactUs';

const Home = () => {
  return (
    <div>
      <Header />
      <ContentInitial />
      <HowToUse />
      <Depositions />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;

