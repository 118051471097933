import styled from 'styled-components'

export const ListItem = styled.li`
  font-size: 1.25rem;
  color: #b453ff;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  a {
    color: #b453ff;
    text-decoration: none;
  }
  a:hover {
    color: #8838c4;
    transition: all 400ms;
  }
`
