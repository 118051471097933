import React from 'react';
import './ContentInitial.scss';
import Appstore from '../../../../../assets/Appstore.png';
import Playstore from '../../../../../assets/Playstore.png';
import CelularBanner from '../../../../../assets/CelularBanner.png';

const ContentInitial = () => {
  return (
    <div>
      <div className='banner-container'>
        <div className='banner-text'>
          <h1 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 700 }}>
            Conecte-se com o Xarlat
          </h1>
          <p style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 400 }}>
            Flerte, conheça e conecte-se de forma autêntica e descomplicada
          </p>
          <div className='app-logos'>
            <a href="https://play.google.com/store/apps/details?id=com.kiloton.xarlat&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noreferrer"
            >
              <img className='playstore-logo' src={Playstore} alt="Playstore" />
            </a>
            <a href="https://apps.apple.com/br/app/xarlat/id6443441982"
              target="_blank"
              rel="noreferrer"
            >
              <img className='appstore-logo' src={Appstore} alt="Appstore" />
            </a>
          </div>
        </div>
        <div className='banner-image'>
          <img src={CelularBanner} alt="Imagem Banner" />
        </div>
      </div>
    </div>
  );
}

export default ContentInitial;
