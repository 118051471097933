import styled from 'styled-components'

export const HeaderLimit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 100%;

  @media (min-width: 800px) {
    justify-content: flex-start;
    display: flex;
  }
`
