/* eslint-disable max-len */
import React from 'react';
import './Depositions.scss';

const Depositions = () => {
  return (
    <div className="depositions-container">
      <h2 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black' }}>
        Depoimentos
      </h2>
      <div className="depostionC-container">
        <div className="deposition">
          <h3 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black' }}>
            Jarbson Henrique Oliveira Silva - Play Store
          </h3>
          <p style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'regular' }}>
            Esse aplicativo é tudo!!! Perfeitooo, amei
            ❤️❤️❤️
          </p>
        </div>
        <div className="deposition">
          <h3 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black' }}>
            Elisangela Rodrigues - Play Store
          </h3>
          <p style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'regular' }}>
            Ótima experiência, entrega o que promete e é muito bom! Recomendo.
          </p>
        </div>
        <div className="deposition">
          <h3 style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'black' }}>
            Renato Allan - Play Store
          </h3>
          <p style={{ fontFamily: 'Telegraf, sans-serif', fontWeight: 'regular' }}>
            Muito bom
          </p>
        </div>
      </div>
    </div>
  );
}

export default Depositions;
