import styled from 'styled-components'

export const Drawer = styled.div`
  height: calc(100vh - 30px);
  width: 70%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #b453ff;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 800px) {
    display: none;
  }
`
