import styled from 'styled-components'

export const Description = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2rem;
  margin-block: 3px;
  color: #caa0ff;

  @media (min-width: 800px) {
  }
`
